import type React from "react";

import { useThemeState } from "@goalteller/app-kit/wo-library/contexts/ThemeContext";
import clsx from "clsx";

import styles from "./themeWrapper.module.css";

function ThemeWrapper({
  children,
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  theme,
}: {
  children: React.ReactNode;
  className?: string;
  theme?: "dark" | "light";
}) {
  const { activeTheme } = useThemeState();

  return (
    <div className={clsx(styles.root, className)} data-theme={activeTheme}>
      {children}
    </div>
  );
}

export default ThemeWrapper;
